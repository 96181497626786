import React, { Component } from "react"
import { routes } from "../routes/routes"
import { Link } from "gatsby"

class FourOhFour extends Component {
  componentDidMount() {
    document.title = "Oops, there was a problem..."
  }
  render() {
    return (
      <div>
        <section className="hero section-spacing">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">404</h1>
              <h2 className="subtitle">Page not found</h2>
              <Link to={routes.home} />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default FourOhFour
