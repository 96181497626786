import React from "react"
import Layout from "../components/Layout"
import FourOhFour from "../components/404"

const seoProps = {
  title: "404 - Not Found",
  description: "This page was not found",
  pathname: "/not-found",
}

const FourOhFourPage = () => (
  <Layout seoProps={seoProps}>
    <FourOhFour />
  </Layout>
)

export default FourOhFourPage
